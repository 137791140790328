import model from "../core/model";
import Api from "../core/Api";
import Store from "../store";
import config from "../core/config";
import {GetSetting} from "../core/helper";

class Article extends model{
    constructor() {
        super();
        this.title = false;
        this.short = false;
        this.text = false;
        this.image = false;
        this.slug = false;
    }
    static getUrl = 'article/inside-news';
    static getBySlugUrl = 'article/get-article';


    static async getAll(){
        let user = Store.getState().site.user;
        let result = await Api.post(Article.getUrl, {user_id:user.id, api_token:user.api_token});
        let articles = [];
        for(const article of result.article){
            articles.push(Article.loadNew(article));
        }
        return articles;
    }
    static async getBySlug(slug){
        let user = Store.getState().site.user;
        let result = await Api.post(Article.getBySlugUrl, {user_id:user.id, api_token:user.api_token,slug:slug});
        if(result.data.length){
            return Article.loadNew(result.data[0]);
        }
        return {};
    }
    afterFind(){
        const search = '/common/uploads';
        const replace = config.API_DOMAIN+"/common/uploads";
        this.text = this.text.replaceAll(search, replace);
        this.short = this.short.replaceAll(search, replace);
    }
    static async getPrivacyAndTac(){
        let user = Store.getState().site.user;
        let result = await Api.post(Article.getBySlugUrl, {user_id:user.id, api_token:user.api_token,category_id:GetSetting('playground_privacy_category')});
        let articles = [];
        if(result.data.length){
            for(const article of result.data){
                articles.push(Article.loadNew(article));
            }
        }

        return articles;
    }
}
export default Article;
