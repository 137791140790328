import React from "react";
import {copyToClipboard, T} from "../../core/helper";
import Voucher from "../../models/Voucher";
import {siteActions} from "../../store/SiteSlice";
import {useDispatch} from "react-redux";

/**
 * @param {object} params
 * @param {TicketType} params.ticket
 * @param {integer} params.selectedId
 * @param {function} params.handleSelectTicketType
 * */
export default (params) => {
    const dispatch = useDispatch();
    const ticket = params.ticket;
    const selectedId = params.selectedId;
    const handleSelectTicketType = params.handleSelectTicketType;


    const sendLinkRequest = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let voucher = new Voucher();
        voucher.event_id = ticket.event_id;
        voucher.ticket_type_id = ticket.id;
        voucher.available_places = 0;
        voucher.ticket_type_name = ticket._name;
        voucher.is_copy = true;

        voucher.send().then(result => {
            if (result.success) {
                if (result.link) {
                    handleCopyLink(result.link);
                }
            } else {
                dispatch(siteActions.setAlert({title: 'Hiba', message: result.error, level: 'error'}));
            }
        });

    }

    const handleCopyLink = async(link) => {
            dispatch(siteActions.setAlert({
                title: 'Link',
                message: link,
                level: 'copy'
            }));

    }

    const availableText = ()=>{
        if(ticket.affiliate){
            return +ticket.soldOut ? T('sold_out'):'Affiliate';
        }else{
            return +ticket.soldOut ? T('sold_out')+' ('+ticket.available_places + " / " + +ticket.usedPlaces+')':+ticket.available_places + " / " + +ticket.usedPlaces;
        }
    }


    return <div className={`row ticket-row ${selectedId === ticket.id ? 'selected' : ''}`} onClick={() => {
        handleSelectTicketType(ticket)
    }}>
        <div className="col-md-2">
            <div>
            <span className="mobile text-blue"><strong>{T('ticket_type')}:</strong></span> 
            {ticket._name} 
            </div>
            {!!ticket.affiliate && ticket.isActive() &&  <button onClick={(e) => { sendLinkRequest(e)}} className="mt-1 mb-2">Link másolása</button> }
        </div>
        <div className="col-md-2">
            <span className="mobile text-blue"><strong>{T('price')}:</strong></span>
            {ticket.gross_price}
        </div>
        <div className="col-md-2">
            <span className="mobile text-blue"><strong>{T('commission')}:</strong></span>
            {ticket.comission}
        </div>
        <div className="col-md-2">
            <span className="mobile text-blue"><strong>{T('available')}:</strong></span>
            {availableText()}
        </div>
        <div className="col-md-4">
            <span className="mobile text-blue"><strong>{T('description')}:</strong></span>
            <div dangerouslySetInnerHTML={{__html:ticket.type_description}}></div>
        </div>
    </div>
}
