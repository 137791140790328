/**
 * @namespace \core\Model
 * @class Model
 *
 * */
class Model {
    load(obj) {
        try {
            if(typeof obj.name !== 'undefined'){
                obj._name = obj.name;
                delete obj.name;
            }
            Object.assign(this, obj);
            this.afterFind();
        } catch (e) {
            console.log(e.message);
        }
    }
    static loadNew(obj) {
        try {
            let model = new this();
            if(typeof obj.name !== 'undefined'){
                obj._name = obj.name;
                delete obj.name;
            }
            model = Object.assign(model, obj);
            model.afterFind();
            return model;
        } catch (e) {
            console.log(e.message);
        }
    }

    getAttributes() {
        let keys = Object.keys(this);
        let attributes = {};
        for (const key of keys) {
            attributes[key] = this[key];
        }
        return attributes;
    }
    afterFind(){

    }
}

export default Model;
