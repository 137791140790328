import React from "react";
import Header from "./Header";
import {Outlet} from "react-router";
import Alert from "../../components/alert/Alert";

export default (props)=>{
    return <>
        <Header/>
        <Alert/>

        <div className="container-xl app-body"><Outlet/></div>
    </>;
}
