import React, {useEffect} from "react";
import PropTypes from 'prop-types'
import BSPagination from 'react-bootstrap/Pagination'
import {useSearchParams} from "react-router-dom";

/**
 * használat!
 *
 * parent:
 *  kell egy state ami az oldalon lévőitemeket tárolja ( const [eventsOnPage, setEventsOnPage] = useState([]);)
 *  kell egy másik state ami az összes elemet tárolja (const [events, setEvents] = useState([]);)
 *  kell egy függvény ami az eventsOnPage értékét beállítja
 *  (
 *      const handlePagination = (from, to) => {
 *           let _eventsOnPage = events.slice(from, to);
 *           setEventsOnPage(_eventsOnPage);
 *           window.scrollTo(0, 0);
 *       }
 *  )
 *  A megfelelő helyen meg kell hívni a Pagination-t (
 *      <Pagination setItem={handlePagination} count={events.length} itemOnPage={12}/>
 *  )
 *
 *  minden lapozás után meghívódik a handlePagination() a parentben
 *
 * */



const Pagination = props => {
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get('page')?searchParams.get('page'):1;
    var _searchParams = {page:1};

    searchParams.forEach((value, key) => {
        _searchParams[key] = value;
    });

    const pageCount = Math.ceil(props.count / props.itemOnPage);
    let iterateArray = [];
    for (let i = 1; i <= pageCount; i++) {
        iterateArray.push(i);
    }

    //az iterateArray fel van töltve 1-pageCountig a számokkal.
    // ebből most kiszedjük az aktuális oldal -3 tóla számokat aktuális lap +2 ig. ezek lesznek a számok (középen az aktuális és előre hátra 2-2 oldal)
    const paginationKeys = iterateArray.slice((page - 3 > 0 ? page - 3 : 0), page + 2);
    //from és to az az elemnek az indexét mutatja nem az oldalszámot.
    const from = (page - 1) * props.itemOnPage;
    const to = from + props.itemOnPage;

    useEffect(() => {
        props.setItem(from, to, page);
    }, [from, to, props.count]);


    const handlePagination = (number) => {//adott oldalra
        _searchParams = {..._searchParams, page:number};
        setSearchParams(_searchParams);
    }
    const handlePaginationPrev = () => {
        _searchParams = {..._searchParams, page: +page > 1 ? (+page - 1) : 1};
        setSearchParams(_searchParams);
    }
    const handlePaginationNext = () => {
        _searchParams = {..._searchParams, page:  +page < pageCount ? (+page + 1) : pageCount};
        setSearchParams(_searchParams);
    }
    const handlePaginationFirst = () => {
        _searchParams = {..._searchParams, page: 1};
        setSearchParams(_searchParams);
    }
    const handlePaginationLast = () => {
        _searchParams = {..._searchParams, page: pageCount};
        setSearchParams(_searchParams);
    }
    if(props.count > props.itemOnPage) {
        return (
            <div className={`pagination-holder`}>
                <BSPagination>
                    {props.showFirstLast && <BSPagination.First onClick={handlePaginationFirst}/>}
                    {props.showPrevNext && <BSPagination.Prev onClick={handlePaginationPrev}/>}
                    {props.showEllipsis && !paginationKeys.includes(1) && <BSPagination.Ellipsis/>}
                    {paginationKeys.map((item, index) => {
                        return <BSPagination.Item key={index + "_pagination_key"} onClick={() => handlePagination(item)}
                                                  active={+item === +page}>{item}</BSPagination.Item>
                    })}
                    {props.showEllipsis && !paginationKeys.includes(pageCount) && <BSPagination.Ellipsis/>}
                    {props.showPrevNext && <BSPagination.Next onClick={handlePaginationNext}/>}
                    {props.showFirstLast && <BSPagination.Last onClick={handlePaginationLast}/>}
                </BSPagination>
            </div>
        );
    }else{
        return <></>;
    }
}
Pagination.defaultProps = {
    setItem: () => {
        console.log("Nincs megadva setItem függvény")
    },
    itemOnPage: 10,
    showFirstLast: true,
    showPrevNext: true,
    showEllipsis: true,
}
Pagination.propTypes = {
    setItem: PropTypes.func.isRequired,
    count: PropTypes.number,
    itemOnPage: PropTypes.number,
    showFirstLast: PropTypes.bool,
    showPrevNext: PropTypes.bool,
    showEllipsis: PropTypes.bool,
    className: PropTypes.string,
}
export default Pagination;