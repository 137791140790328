import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {siteActions} from "../../store/SiteSlice";
import './alert.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {copyToClipboard} from "../../core/helper";

const Alert = () => {
    const {title, message, level} = useSelector(state => state.site.alert);
    const dispatch = useDispatch();

    const handleCloseAlert = () => {
        dispatch(siteActions.removeAlert());
    }
    const handleCopy = async () => {
        dispatch(siteActions.removeAlert());
        if(await copyToClipboard(message)){
            dispatch(siteActions.setAlert({
                title: 'Vágólapra másolva',
                message: '',
                level: 'success'
            }));
        }else{
            dispatch(siteActions.setAlert({
                title: 'Nem sikerült a vágólapra másolni',
                message: message,
                level: 'error'
            }));
        }
    }
    if (title) {
        return <div className="container">
            <div className={`alert ${level}`}>
                <div className="alert__body">
                    <div className="alert__title">{title}</div>
                    <div className="alert__message" dangerouslySetInnerHTML={{__html:message}}></div>
                </div>
                <div className="alert__close">
                    {level=='copy' && <button type="button" onClick={handleCopy}>
                       Másolás
                    </button>}
                    {level!='copy' && <button type="button" onClick={handleCloseAlert}>
                        <FontAwesomeIcon icon={["fas", "times"]}/>
                    </button>}
                </div>
            </div>
        </div>;
    } else {
        return <></>;
    }
};

export default Alert;
