function getConfig() {
    let config = {};
    //a window konstansok a public/envs.js ben vannak
    config.API_DOMAIN = window.API_DOMAIN;
    config.APP_DOMAIN = window.APP_DOMAIN;
    config.APP_VERSION = window.APP_VERSION;
    config.API_URL = config.API_DOMAIN+'/rest/v1/';
    config.POST_HEADER = {headers: {"Content-Type": "application/json"}};
    return config;
}

const config = getConfig();
export default config;
