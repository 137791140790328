import React, {useRef, useState} from "react";
import validator from "./validators";

/**
 *  custom hook
 *  pl const object = useInput(params);
 *  return {
 *      jsx: az input jsx kódja
 *      validate: függvény ami validálja az inputot
 *      value: aktuális értéke az inputnak
 * }
 *
 *
 *
 * rules használata:
 * rules={['required',{key:min, minval:10,message:"asd"}]}
 *
 * a megadható validatorok a validators-ban vannak
 * @param {Object} props
 * @param {string} props.id - Input type attribute value
 * @param {string} [props.value = null] - Input value attribute
 * @param {string|null} [props.label = null] - Input label text
 * @param {boolean} [props.showLabel = true] - Show input label (true|false)
 * @param {string} [props.className = null] - Additional class name
 * @param {bool} [props.showErrorBlock = true] - Show error block (true|false)
 * @param {function} [props.afterValidate = null] -  Run after all validation
 * @param {string|[]} [props.rules = []] - Array of validators
 * @param {[]} [props.options = []] - Array of validators
 *

 * @return {ref, jsx, validate}

 */

const useSelect = (props) => {
    const [error, setError] = useState("");
    let inputRef = useRef();
    const {id, value, label, showLabel, className, showErrorBlock, afterValidate, rules, options} = setDefaultProps(props);

    const validation = () => {
        let message = "";
        let result = {isValid: true, error: false};
        const inputValue = inputRef.current.value;
        for (const rule of rules) {
            if (typeof rule === "string") {
                result = validator(rule, inputValue);
            } else if (typeof rule === "object") {
                if (!rule.key) {
                    Error(
                        'Nincs meagdva "key" paramérter a validálás szabályában!'
                    );
                }
                const key = rule.key;
                result = validator(key, inputValue, rule);
            }
            if (!result.isValid) {
                message = result.error;
                message = message.replace('{name}', label);
                break;
            }
        }

        setError(message);
        if (afterValidate) {
            afterValidate(!!error, inputValue);
        }
        return result.isValid;
    };


    const inputBlock = (
        <div className={`input ${className}`}>
            {!!label && !!showLabel && (
                <label className="form-label" htmlFor={id}>
                    {label}
                </label>
            )}

            <select defaultValue={value} className="form-control" ref={inputRef} name="" id={id} onBlur={validation}>
                {options.map((item,index) => <option key={id+"_"+index} value={item.key}>{item.value}</option>)}
            </select>

            {!!error && showErrorBlock && (
                <div className="invalid-feedback">{error}</div>
            )}
        </div>
    );
    return {
        jsx: inputBlock,
        validate: validation,
        ref: inputRef
    };
};


function setDefaultProps(props) {
    return {
        id: props.id,// input id-ja
        value: props.value ? props.value : null, // input value attributum értéke
        label: props.label ? props.label : null, // az input label szövege
        showLabel: props.showLabel ? props.showLabel : true, // input label megjelenítése igen/nem
        className: props.className ? props.className : '',
        showErrorBlock: props.showErrorBlock ? props.showErrorBlock : true,
        afterValidate: props.afterValidate ? props.afterValidate : null,
        rules: props.rules ? props.rules : [],
        options: props.options ? props.options : []
    }
}

export default useSelect;
