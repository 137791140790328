import React, {useRef} from "react";
import User from "../../models/User";
import {T} from "../../core/helper";
import {Link} from "react-router-dom";


export default (props) => {
    let user = new User();
    const emailInputRef = useRef();
    const passwordInputRef = useRef();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (emailInputRef?.current?.value && passwordInputRef?.current?.value) {
            user.email = emailInputRef.current.value;
            user.password = passwordInputRef.current.value;
            user.login().then(user=>{
                if (user) {
                    user.getPromoterData();
                }
            });
            emailInputRef.current.value = '';
            passwordInputRef.current.value = '';
        }
    }
    return <>
        <div className="login-holder">
            <div className="login-outer">
                <div className="login-inner">
                    <form onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="email">{T('email')}</label>
                            <input id="email" type="text" ref={emailInputRef}/>
                        </div>
                        <div>
                            <label htmlFor="password">{T('password')}</label>
                            <input id="password" type="password" ref={passwordInputRef}/>
                        </div>
                        <div className="d-flex flex-column">
                            <div><Link to="forgot-password" className="text-white" style={{fontSize: '14px'}} type="submit">{T('forgot_password')}</Link></div>
                            <div><button className="btn login-btn" style={{fontSize: '14px'}} type="submit">{T('login')}</button></div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>;
}
