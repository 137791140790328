import React, {useEffect, useState} from "react";
import Article from "../../models/Article";
import ArticleItem from "./ArticleItem";
import {useDispatch, useSelector} from "react-redux";
import {siteActions} from "../../store/SiteSlice";
import ScrollToAnchor from "../../components/scroll_to_anchor/ScrollToAnchor";


export default () => {
    const articles = useSelector(state=>state.site.articles);
    const dispatch  = useDispatch();
    useEffect(() => {
        Article.getAll().then((_articles) => {
            dispatch(siteActions.articles(_articles));
        });
    }, []);
    if(articles.length==0){
        return <></>;
    }
    return <div className="row mobile-scroll">
        {!!articles && articles.map((article, index) => <div key={index+"_article"} className="col-sm-4 col-10 article__item"><ArticleItem article={article}/></div>)}
        <ScrollToAnchor/>

    </div>;
}