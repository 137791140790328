import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToAnchor() {
    const location = useLocation();
    const lastHash = useRef('');

    // listen to location change using useEffect with location as dependency
    // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
    useEffect(() => {
        let hash = null;
        if (location.hash) {
            hash = location.hash.slice(1); // safe hash for further use after navigation
        }
        setTimeout(() => {
            if (hash && window.document.getElementById(hash)) {
                window.document.getElementById(hash).scrollIntoView({ behavior: 'smooth', block: 'start' });
                hash = '';
            }
        }, 100);
    }, [location]);

    return <></>;
}

export default ScrollToAnchor;