import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Modal from 'react-bootstrap/Modal';
import {copyToClipboard, T} from "../../core/helper";
import Voucher from "../../models/Voucher";
import Event from "../../models/Event";
import TicketType from "../../models/TicketType";
import {siteActions} from "../../store/SiteSlice";

const VoucherForm = (props) => {
    const eventFromParent = props.event || false;
    const ticketTypesFromParent = props.ticketTypes || false;
    const vouchers = useSelector(state => state.site.vouchers);
    const eventInputRef = useRef();

    const nameTypeInputRef = useRef();
    const emailTypeInputRef = useRef();
    const clipboardRef = useRef();

    const [amount, setAmount] = useState(0);
    const [error, setError] = useState({});
    const [ticketType, setTicketType] = useState([]);
    const [selectedIsAffiliate, setSelectedIsAffiliate] = useState(false);
    const [changeCounter, setChangeCounter] = useState(0);
    const [events, setEvents] = useState([]);
    const [ticketTypes, setTicketTypes] = useState([]);
    const [voucherQrCode, setVoucherQrCode] = useState("");
    const [selectedTicketType, setSelectedTicketType] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        const initPage = async () => {
            let events;
            let tickets = {};
            if(!!eventFromParent && !!ticketTypesFromParent){
                events = [eventFromParent];
                ticketTypesFromParent.forEach(ticket => {
                    if (ticket.isActive()) {
                        if (tickets[ticket.event_id]) {
                            tickets[ticket.event_id].push(ticket);
                        } else {
                            tickets[ticket.event_id] = [ticket];
                        }
                    }
                });
               
            } else {
                [events, tickets] = await Promise.all([
                    Event.getAll(),
                    TicketType.get(eventFromParent.id ?? false).then(result => {
                        const _eventTicket = {};
                        result.forEach(ticket => {
                            if (ticket.isActive()) {
                                if (_eventTicket[ticket.event_id]) {
                                    _eventTicket[ticket.event_id].push(ticket);
                                } else {
                                    _eventTicket[ticket.event_id] = [ticket];
                                }
                            }
                        });
                        return _eventTicket;
                    })
                ]);
            }
            return { events, tickets };
        };

        initPage().then(result => {
            setEvents(result.events);
            setTicketTypes(result.tickets);
        });
    }, []);

    useEffect(() => {
        if (eventFromParent) {
            initSelectEvent(eventFromParent.id);
        } else {
            const activeEvent = events.find(event => event.isActive() && ticketTypes[event.id] && ticketTypes[event.id].length > 0);
            if (activeEvent) {
                initSelectEvent(activeEvent.id);
            }
        }
    }, [events, ticketTypes]);

    useEffect(() => {
        changeTicketType();
    }, [ticketType]);

    const initSelectEvent = (eventId) => {
        const filtered = ticketTypes[eventId];
        if (filtered) {
            const ticket = filtered[0];
            setSelectedIsAffiliate(!!ticket.affiliate);
        }
        setTicketType(filtered);
    };

    const handleSelectEvent = () => {
        const eventId = eventInputRef.current.value;
        const filtered = ticketTypes[eventId];
        setTicketType(filtered);
        setSelectedTicketType(null);
    };

    const handleSubmitVoucher = (e, copy = false, qr = false) => {
        e.preventDefault();
        let eventId = eventInputRef?.current?.value;
        if (!eventId && eventFromParent.id) {
            eventId = eventFromParent.id;
        }
        let isValid = true;
        let error = {};
        if (eventId && selectedTicketType) {
            const _ticketType = ticketType.find(item => +item.id === +selectedTicketType);
            const voucher = new Voucher();
            voucher.event_id = eventId;
            voucher.ticket_type_id = selectedTicketType;
            if (selectedIsAffiliate) {
                voucher.recipient_name = null;
                voucher.email = null;
                voucher.available_places = 1;
            } else {
                voucher.available_places = selectedIsAffiliate || amount;
                if (!voucher.available_places) {
                    error = {...error, available_places: 'A mennyiség megadása kötelező'};
                    isValid = false;
                }
                voucher.recipient_name = selectedIsAffiliate || nameTypeInputRef.current.value ? nameTypeInputRef.current.value : null;
                voucher.email = selectedIsAffiliate || emailTypeInputRef.current.value ? emailTypeInputRef.current.value : null;
                if (!copy && !qr) {
                    voucher.recipient_name = selectedIsAffiliate || nameTypeInputRef.current.value ? nameTypeInputRef.current.value : null;
                    if (!voucher.recipient_name) {
                        error = {...error, recipient_name: 'A név megadása kötelező'};
                        isValid = false;
                    }
                    voucher.email = selectedIsAffiliate || emailTypeInputRef.current.value ? emailTypeInputRef.current.value : null;
                    if (!voucher.email) {
                        error = {...error, email: 'Az email megadása kötelező'};
                        isValid = false;
                    }
                }
            }
            if (!isValid) {
                setError(error);
                return;
            }
            voucher.ticket_type_name = _ticketType._name;
            voucher.is_copy = copy;

            voucher.send().then(result => {
                if (result.success) {
                    if (result.link && copy) {
                        handleCopyLink(result.link);
                    }
                    if (!selectedIsAffiliate) {
                        nameTypeInputRef.current.value = '';
                        emailTypeInputRef.current.value = '';
                        setAmount(1);
                    }

                    if (vouchers.selectedId == selectedTicketType) {
                        const newVouchers = {
                            selectedId: vouchers.selectedId,
                            vouchers: [...vouchers.vouchers, Voucher.loadNew(result.voucher)]
                        };
                        dispatch(siteActions.vouchers(newVouchers));
                    }
                    if (!((result.link && copy) || qr)) {
                        dispatch(siteActions.setAlert({title: 'Sikeres küldés', message: '', level: 'success'}));
                    }

                    if (qr) {
                        setVoucherQrCode(result.voucherQrCode);
                    }
                    setError({});
                } else {
                    dispatch(siteActions.setAlert({title: 'Hiba', message: result.error, level: 'error'}));
                }
            });
        } else {
            dispatch(siteActions.setAlert({
                title: 'Hiba',
                message: 'Nincs kiválasztott Esemény vagy jegytípus',
                level: 'error'
            }));
        }
    };

    const openShareMenu = async (link) => {
        try {
            await navigator.share({
                url: link,
            });
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    };

    const handleCopyLink = async (link) => {
        dispatch(siteActions.setAlert({
            title: 'Link',
            message: link,
            level: 'copy'
        }));
    };

    const copyLink = (e) => {
        handleSubmitVoucher(e, true);
    };

    const getQrCode = (e) => {
        handleSubmitVoucher(e, false, true);
    };

    const changeTicketType = (id) => {
        setSelectedTicketType(id);
       
    };
    useEffect(()=>{
        if (selectedTicketType && ticketTypes) {
            const selected = ticketType.find(item => item.id == selectedTicketType);
            setSelectedIsAffiliate(selected.affiliate);
        }
    },[selectedTicketType]);
    const getSendButtonClassName = () => {
        return nameTypeInputRef.current?.value &&
        emailTypeInputRef.current?.value &&
        amount ? '' : 'btn-disabled';
    };

    const triggerChange = () => {
        setChangeCounter(changeCounter + 1);
    };

    const incAmount = () => {
        setAmount(amount + 1);
    };

    const decAmount = () => {
        if (amount - 1 > 0) {
            setAmount(amount - 1);
        }
    };

    const handleClose = () => setVoucherQrCode('');

    if (!(!!ticketTypes && !!events && !!ticketType)) {
        return <></>;
    }

    return (
        <form onSubmit={handleSubmitVoucher}>
            <div className="voucher__send">
                <Modal show={voucherQrCode && voucherQrCode.length > 0} onHide={handleClose}>
                    <Modal.Body>
                        <div><img className="w100" src={voucherQrCode} alt=""/></div>
                    </Modal.Body>
                </Modal>
                <div className="row">


                    {!!eventFromParent && <div className="col-sm-12">
                        <div className="input-block">
                            <label>{T('ticket_type')}</label>
                            {!!ticketType && ticketType.filter(_ticketType => _ticketType.isActive()).map((_ticketType, i) =>
                                <button
                                    type="button"
                                    key={i + "ticket-select-option"}
                                    onClick={() => {
                                        changeTicketType(_ticketType.id);
                                    }}
                                    className={'btn btn-ticket-type text-start fw-normal ' + (selectedTicketType == _ticketType.id ? 'active' : '')}>{_ticketType._name}</button>)
                            }
                        </div>
                    </div>}
                    {!eventFromParent && <div className="col-sm-4">
                        <div className="input-block">
                            <label>{T('event')}</label>
                            <div className="select-wrapper">
                                <select ref={eventInputRef} defaultValue={eventFromParent.id} onChange={handleSelectEvent}>
                                    {!!events && events.filter(event => (event.isActive() && ticketTypes[event.id] && ticketTypes[event.id].length > 0))
                                        .map((event, i) => <option key={i + "event-select-option"} value={event.id}>{event._name}</option>)}
                                </select>
                            </div>
                            <small className="error">{error['event']}</small>
                        </div>
                        <div className="input-block">
                            <label>{T('ticket_type')}</label>

                            {!!ticketType && ticketType.filter(_ticketType => _ticketType.isActive()).map((_ticketType, i) =>
                                <button
                                    type="button"
                                    key={i + "ticket-select-option"}
                                    onClick={() => {
                                        changeTicketType(_ticketType.id);
                                    }}
                                    className={'btn btn-ticket-type  text-start fw-normal ' + (selectedTicketType == _ticketType.id ? 'active' : '')}>{_ticketType._name}</button>)
                            }
                        </div>
                    </div>}
                    {!selectedIsAffiliate && 
                        <div className="col-sm-4">
                            <div className="input-block">
                                <label>{T('name')}</label>
                                <input ref={nameTypeInputRef} type="text" onBlur={triggerChange}/>
                                <small className="error">{error['recipient_name']}</small>
                            </div>
                            <div className="input-block">
                                <label>{T('email')}</label>
                                <input ref={emailTypeInputRef} type="email" onBlur={triggerChange}/>
                                <small className="error">{error['email']}</small>
                            </div>
                        </div>
                    }
                    <div className="col-sm-4">
                    {!selectedIsAffiliate && <div className="input-block">
                            <label>{T('quantity')}</label>
                            <div className="row align-items-center">
                                <div className="col-4 text-right">
                                    <button type="button" onClick={decAmount} className="btn btn-sm">-</button>
                                </div>
                                <div className="col-4 text-center">
                                    {amount}
                                </div>
                                <div className="col-4">
                                    <button type="button" onClick={incAmount} className="btn btn-sm">+</button>
                                </div>
                            </div>
                            <small className="error">{error['available_places']}</small>
                        </div>
}
                        <div className="input-block mt-sm-5">
                            <div className="row mt-3 mt-lg-4">
                                <div className="col-sm-4">
                                    <button className="w100 flex-grow-1 btn mt-2" type="button"
                                            onClick={copyLink}>Link <FontAwesomeIcon icon="fa-solid fa-link"/></button>
                                </div>
                                {!selectedIsAffiliate && <div className="col-sm-4">
                                    <button className={"w100 flex-grow-1 btn mt-2 "} type="button"
                                            onClick={getQrCode}>QR <FontAwesomeIcon icon="fa-solid fa-qrcode"/></button>
                                </div>
                                }
                                {!selectedIsAffiliate && <div className="col-sm-4">
                                    <button className={"w100 flex-grow-1 btn mt-2 " + getSendButtonClassName()}
                                            type="submit">Küldés <FontAwesomeIcon icon="fa-solid fa-paper-plane"/>
                                    </button>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <input ref={clipboardRef} id="clipboard"
                       style={{display: 'none', height: 0, width: 0, position: 'absolute'}}/>
            </div>
        </form>
    );
};

export default VoucherForm;
