import React from "react";
import config from "../../core/config";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default ({event}) => {

    return <div className=" card">
        <Link to={"/promoter/event/" + event.slug}>
            {!!event.header_image && <div className="event-image-holder" style={{backgroundImage:"url("+config.API_DOMAIN + event.header_image+")"}}>
            </div>}
            <h3 className="title">{event._name}</h3>
            <p className="text-pink fw-500">
                <span className="me-4">
                    <span className="text-blue"><FontAwesomeIcon icon="fas fa-map-marker-alt"/></span>
                    &nbsp;{event.location_name}
                </span>
                <span>
                    <span className="text-blue"><FontAwesomeIcon icon="fas fa-calendar-alt"/></span>
                    &nbsp;{event.start_date}
                </span>
            </p>
        </Link>
    </div>;
}
