import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import store from "./store";

import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';

import 'bootstrap/dist/css/bootstrap.min.css';
import './style/css/site.css';


library.add(fas);
library.add(far);

if (!navigator.onLine) {
    alert("Nem kapcsolódik az internethez!");
}

const render = () => ReactDOM.render(
    <Provider store={store}>
        {navigator.onLine && <App/>}
    </Provider>
    ,
    document.getElementById('root')
);
render();
serviceWorker.register();

reportWebVitals();

