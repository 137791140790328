import {T} from "../../core/helper";
import User from "../../models/User";
import config from "../../core/config";
import React, {useEffect, useState} from "react";
import {siteActions} from "../../store/SiteSlice";
import {useDispatch} from "react-redux";

export default ({url}) => {
    const user = User.get();
    const dispatch = useDispatch();
    let [isSending,setIsSending] = useState(false);
    const handleFormSubmit = (e) => {
        e.preventDefault();
        if(isSending){
            return;
        }
        setIsSending(true);
    }
    useEffect(()=>{
        if(isSending) {
            sendFile().then( _ => {
                setIsSending(false);
            });
        }
    },[isSending]);

    const sendFile = async ()=>{
        let data = new FormData();
        let imagedata = document.querySelector('#file-upload input[type="file"]').files[0];
        let api_token = document.querySelector('#file-upload #file-upload-api_token').value;
        let user_id = document.querySelector('#file-upload #file-upload-user_id').value;
        if (imagedata) {
            data.append("image", imagedata);
            data.append("api_token", api_token);
            data.append("user_id", user_id);
            await fetch(config.API_URL + url, {
                method: "POST",
                body: data,
            }).then(function (res) {
                if (res.ok) {
                    res.json().then(result=>{
                        if(result.success){
                            let user = User.loadNew(result.user);
                            user.getPromoterData().then(result=>{
                                if(result) {
                                    dispatch(siteActions.setAlert({
                                        title: 'Sikeresen módosította a profilképet',
                                        message: '',
                                        level: 'success'
                                    }));
                                }
                            });
                        }else{
                            alert(result.error);

                        }
                    })
                } else  {
                    alert("A képet nem sikerült feltölteni! ");
                }
            }, function (e) {
                alert("A képet nem sikerült feltölteni! ");
            });
        }
        return true;
    }

    return <div>
        <form id="file-upload" onSubmit={handleFormSubmit}>
            <input id="file-upload-api_token" type="hidden" name="api_token" value={user.api_token}/>
            <input id="file-upload-user_id" type="hidden" name="user_id" value={user.id}/>
            <div className="mb-3">
                <input type="file" name="filename" accept="image/*"/>
            </div>
            <div>
                <button className="btn" type="submit">{T("upload")}</button>
            </div>
        </form>
    </div>;
}
