
import React from "react"
import { useSelector } from "react-redux";
import config from "../../core/config";

const ProfileImage = props => {
    const user = useSelector(state => state.site.user);

    return <div className={"profile-image-round  mx-auto m-md-0"+(props.isSm?' sm':'')}>
        <div className="profile-image" style={{backgroundImage: "url('"+config.API_DOMAIN + user.promoter_image+"')"}}>
    </div>
    </div>
}
export default ProfileImage;