import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Dropdown from 'react-bootstrap/Dropdown';
import User from "../../models/User";
import config from "../../core/config";
import boomLogoMobile from "../../style/img/mobile-header.png";
import logo from "../../style/img/boom_logo.png";
import ProfileImage from "../../components/profileImage/ProfileImage";

export default (props) => {
    const user = useSelector(store => store.site.user);
    let headerImg = useSelector(store => store.site.headerImg);

    const handleLogout = () => {
        let user = User.get();
        user.logout();
    }

    return <nav className="header">
        <div className="container " style={headerImg ? { 'backgroundImage': `url("${config.API_DOMAIN + headerImg}")` } : {}}>

            <div className="row align-items-center">
                <div className="col-6">
                    <div className="logo">
                        <Link to="/">
                            <img src={logo} alt="" />
                        

                        </Link>
                    </div>
                </div>
                {!props.hideNav && <div className="col-6 text-right">
                    {!!user && <div className={"d-flex align-items-center flex-column-reverse flex-md-row justify-content-end "}>
                        <Dropdown>
                            <Dropdown.Toggle className="nav-button">
                                {user._profile.name}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Link className="dropdown-item" to="/profile">Profil</Link>
                                <button className="dropdown-item" onClick={() => { user.logout(); }}>Kijelentkezés</button>
                                <div style={{ textAlign: 'right', paddingRight: '10px' }}><small
                                    style={{ color: '#aaaaaa' }}>{config.APP_VERSION}</small></div>
                            </Dropdown.Menu>
                        </Dropdown>
                        <ProfileImage isSm={true}/>
                    </div>}

                </div>}
            </div>
        </div>
    </nav>;
}
