import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Article from "../models/Article";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { siteActions } from "../store/SiteSlice";
import { GetSetting, T } from "../core/helper";
import Store from "../store";
import ArticleItem from "./partials/ArticleItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PromoterHeader from "./layout/PromoterHeader";
import Alert from "../components/alert/Alert";
import boomLogoMobile from "../style/img/mobile-header.png";
import config from "../core/config";


export default (props) => {
    const params = useParams();
    const [article, setArticle] = useState(false);
    const [articles, setArticles] = useState([]);
    const dispatch = useDispatch();
    const [currIndexInList, setCurrIndexInList] = useState(0);
    let headerImg = useSelector(store => store.site.headerImg);

    useEffect(() => {
        Article.getBySlug(params.slug).then((_article) => {
            setArticle(_article);
            if (_article.category_id.includes(+GetSetting('playground_privacy_category'))) {
                let _articles = Store.getState().site.privacyArticles;
                if (_articles == 0) {
                    Article.getPrivacyAndTac().then((_articles) => {
                        dispatch(siteActions.privacyArticles(_articles));
                        setArticles(_articles);
                        let currIndex = _articles.findIndex(item => item.id == _article.id);
                        setCurrIndexInList(currIndex);
                    });
                } else {
                    setArticles(_articles);
                    let currIndex = _articles.findIndex(item => item.id == _article.id);
                    setCurrIndexInList(currIndex);
                }
            } else {
                let _articles = Store.getState().site.articles;
                if (_articles == 0) {
                    Article.getAll().then((_articles) => {
                        dispatch(siteActions.articles(_articles));
                        setArticles(_articles);
                        let currIndex = _articles.findIndex(item => item.id == _article.id);
                        setCurrIndexInList(currIndex);
                    });
                } else {
                    setArticles(_articles);
                    let currIndex = _articles.findIndex(item => item.id == _article.id);
                    setCurrIndexInList(currIndex);
                }
            }
            dispatch(siteActions.headerImg(_article.image));
        });
        window.scrollTo(0, 0);
        return () => { dispatch((siteActions.headerImg(null))); }
    }, [params.slug]);


    if (article === false) {
        return <div></div>;
    }
    return <div>
       <div className="d-none d-md-block header-img">
            <PromoterHeader hideNav="true"/>
        </div>
        <div className="d-block d-md-none header-img" >
            <div className="promoter-page-header align-items-center">
                <img className="d-md-none w100 img-fluid" src={headerImg?config.API_DOMAIN+headerImg: boomLogoMobile} alt="" />
            </div>
        </div>
        <Alert />
        <div className="panel">
            <div className="back-btn-holder">
                <Link to="/promoter" className="btn btn-outline"><FontAwesomeIcon icon="fas fa-arrow-left"/> </Link>
            </div>
            <div className="article__page">
                <p className="text-pink fw-500 mb-0">{article.subtitle}</p>
                <h1 className="title">{article.title}</h1>
                <div className="short" dangerouslySetInnerHTML={{ __html: article.short }}></div>
                <div className="short" dangerouslySetInnerHTML={{ __html: article.text }}></div>
            </div>
            <div className="row mb-4 mt-4">
                <div className="col-6 text-left">
                    {currIndexInList > 0 && <Link to={"/promoter/inside-article/" + articles[currIndexInList - 1].slug} className="btn"><FontAwesomeIcon icon="fas fa-arrow-left" /> {T('prev')}</Link>}
                </div>
                <div className="col-6 text-right">
                    {currIndexInList < (articles.length - 1) && <Link to={"/promoter/inside-article/" + articles[currIndexInList + 1].slug} className="btn">{T('next')} <FontAwesomeIcon icon="fas fa-arrow-right" /></Link>}
                </div>
            </div>
            <div>
                <h2 className="title"><span className="icon">
                    <FontAwesomeIcon icon="fas fa-graduation-cap" /></span>&nbsp;{T('find_out_everything')}</h2>
            </div>
            <div className="row mobile-scroll">
                {articles.length > 0 && articles.map((_article, index) => {
                    //azt ami meg van nyitva, itt nem kell megjeleníteni
                    if (_article.id == article.id) {
                        return <React.Fragment key={'_article_view_' + index + "_"}></React.Fragment>;
                    }
                    return <div key={'_article_view_' + index + "_"} className="col-sm-4 col-10 article__item">
                        <ArticleItem article={_article} />
                    </div>
                })}
            </div>
        </div>


    </div>;
}