import React, {useEffect, useState} from "react";
import config from "../../core/config";
import EventModel from "../../models/Event";

export default ({ticketCheck, handleSelectEvent}) => {
    const [events, setEvents] = useState([]);
    useEffect(()=>{
        EventModel.getAll(false, ticketCheck).then((futureEvents) => {
            setEvents(futureEvents);
        });

    },[ticketCheck]);

    return <div className="row">
        {!!events && events.map((event, index) => <div key={index + "_event"} className="event__item row"
                                                       onClick={()=>{handleSelectEvent(event)}}>
                <div className={"col-sm-3 event-list-image"}>
                {!!event.header_image && <div className="event-image-holder" style={{backgroundImage:"url("+config.API_DOMAIN + event.header_image+")"}}>
                </div>}</div>
                <div className={"col-sm-9"}>
                    <h3 className="event-title">{event._name}</h3>
                    <p className="event-date">{event.start_date} - {event.end_date.split(' ')[1]}</p>
                </div>
                <hr/>
            </div>
        )}
    </div>;
}
