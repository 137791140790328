import { configureStore } from "@reduxjs/toolkit";
import SiteSlice from "./SiteSlice";

const Store = configureStore({
    reducer:{site:SiteSlice.reducer},
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),

});
export default Store;