const required = (value, obj) => {
    return !!value ? false : 'A(z) {name} kitöltése közelező!';
}
const min = (value, obj) => {
    return +value >= obj.val ? false : 'A(z) {name} értéke túl kicsi';
}
const max = (value, obj) => {
    return +value <= obj.val ? false : 'A(z) {name} értéke túl nagy';
}
const email = (value, obj) => {
    return value.includes('@') && value.includes('.') ? false : 'A(z) {name} formátuma nem megfelelő';
}
const compare = (value, obj) => {
    switch (obj.operator) {
        case '<': return +value < obj.val ? false: 'a megadott érték nagyobb vagy egyenlő mint' + obj.val;
        case '<=': return +value <= obj.val ? false : 'a megadott érték nagyobb mint' + obj.val;
        case '>': return +value > obj.val ? false : 'a megadott érték kisebb vagy egyenlő mint' + obj.val;
        case '>=': return +value >= obj.val ? false : 'a megadott érték kisebb mint' + obj.val;
        case '==':
            if(isNaN(value) && isNaN(obj.val)){
                return value == obj.val ? false : 'a megadott érték nem egyezik meg ' + obj.val
            }else{
                return +value == +obj.val ? false : 'a megadott érték nem egyezik meg ' + obj.val;
            }
        case '!': return !value == obj.val ? false : 'a megadott érték nem egyezhet meg ' + obj.val;
        default: console.error("Validator (" + obj.operator + ") is not exist at line: 14");
    }
}
const validators = {
    required: required,
    min: min,
    max: max,
    email: email,
    compare: compare
}

const validator = (func, value, obj = {}) => {
    let result = validators[func](value, obj); //hibaüzenetet ad vissza ha nem valid. ha valid akkor üres 
    return {
        isValid: !result,
        error: obj.message ? obj.message : result
    }
}
export default validator;