import React from "react";
import {Outlet} from "react-router";
import Alert from "../../components/alert/Alert";
import PromoterHeader from "./PromoterHeader";
import ScrollToAnchor from "../../components/scroll_to_anchor/ScrollToAnchor";


export default (props)=>{
    return <div className="promoter-page">
       
        <div className="container-xl app-body"><Outlet/></div>
    </div>;
}
