import React, { useEffect, useState } from "react";
import Api from "../core/Api";
import User from "../models/User";
import useInput from "../components/input/Input";
import { Link } from "react-router-dom";
import PromoterHeader from "./layout/PromoterHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moneyIconBlue from "../style/img/money-icon-blue.svg"
import { T } from "../core/helper";
const GetPerformanceReport = async (props) => {
    let user = User.get();
    let result = await Api.post('user/promoter-statistic', {
        user_id: user.id,
        api_token: user.api_token,
    });
    return !!result ? result.statistic : false;

}
export { GetPerformanceReport };

export default (props) => {
    const currentTime = new Date();
    const year = currentTime.getFullYear();

    const [statistics, setStatistics] = useState([]);
    const dateFromInput = useInput({ id: 'date-from', type: 'date', showLabel: false, value: year + '-01-01' });
    const dateToInput = useInput({ id: 'date-from', type: 'date', showLabel: false, value: year + '-12-31' });

    useEffect(() => {
        const init = async () => {
            let user = User.get();
            let result = await Api.post('user/promoter-statistic', {
                user_id: user.id,
                api_token: user.api_token,
            });
            setStatistics(result.statistic);
        };
        init();
    }, []);

    const submitFilter = async (e) => {
        e.preventDefault();
        let user = User.get();
        let result = await Api.post('user/promoter-statistic', {
            user_id: user.id,
            api_token: user.api_token,
            form_date: dateFromInput.ref.current.value,
            to_date: dateToInput.ref.current.value,
        });
        setStatistics(result.statistic);
    }
    return <div className="white-bg-mobile">
        <div className="d-none d-md-block">
            <PromoterHeader />
        </div>
        <div className="panel mb-4 br-sm-0">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="profile-back-btn-holder">
                    <Link to="/promoter" className="btn btn-outline me-2"><FontAwesomeIcon icon="fas fa-arrow-left" /> </Link>
                    <img src={moneyIconBlue} alt="" /> <span className="fw-700"> { T('performance_report')}</span>
                </div>

            </div>
            
            <div className="filter-box mt-4 mb-4">
                <h3 className="title"><span className="icon me-2"><FontAwesomeIcon icon="fas fa-sliders-h" /></span>{T('edit_filters')}</h3>
                <form onSubmit={submitFilter}>
                    {dateFromInput.jsx}
                    {dateToInput.jsx}
                    <button className="btn" type="submit">Szűrés</button>
                </form>
            </div>
            


            <div className="performance-grid grid-col-9">
                <div className="desktop performance-grid__header">Esemény kezdő dátum</div>
                <div className="desktop performance-grid__header">Esemény neve</div>
                <div className="desktop performance-grid__header">Helyszín név</div>
                <div className="desktop performance-grid__header">Helyszín cím</div>
                <div className="desktop performance-grid__header">Jegytípus</div>
                <div className="desktop performance-grid__header">Jegyár</div>
                <div className="desktop performance-grid__header">Jutalék</div>
                <div className="desktop performance-grid__header">Összes jutalék</div>
                <div className="desktop performance-grid__header">Kiküldött jegy lehetőségek száma / Letöltött jegyek száma</div>
                {statistics.map((statistic, index) => <React.Fragment key={index}>
                    <div>
                        <span className="mobile"><strong>Esemény kezdő dátum: </strong></span>
                        {statistic.event_start_date}</div>
                    <div>
                        <span className="mobile"><strong>Esemény neve: </strong></span>
                        {statistic.event_name}</div>
                    <div>
                        <span className="mobile"><strong>Helyszín név: </strong></span>
                        {statistic.event_location_name}</div>
                    <div>
                        <span className="mobile"><strong>Helyszín cím: </strong></span>
                        {statistic.event_location_address}</div>
                    <div>
                        <span className="mobile"><strong>Jegytípus: </strong></span>
                        {statistic.ticket_type_name}</div>
                    <div>
                        <span className="mobile"><strong>Jegyár: </strong></span>
                        {statistic.ticket_type_gross_price}</div>
                    <div>
                        <span className="mobile"><strong>Jutalék: </strong></span>
                        {statistic.comission}</div>
                    <div>
                        <span className="mobile"><strong>Összes jutalék: </strong></span>
                        {statistic.transaction_amount}</div>
                    <div>
                        <span className="mobile"><strong>Kiküldött jegy lehetőségek száma / Letöltött jegyek száma: </strong></span>
                        {statistic.original_count_voucher}/{statistic.count_voucher}
                    </div>
                    <hr className="hr-blue mobile-block" />
                </React.Fragment>
                )}
            </div>
        </div>
    </div>;
}
