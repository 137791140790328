import Api from "../core/Api";
import { T } from "../core/helper";
import User from "../models/User";
import config from "../core/config";
import { Collapse } from "react-bootstrap";
import { siteActions } from "../store/SiteSlice";
import React, { useEffect, useState } from "react";
import useInput from "../components/input/Input";
import { useDispatch, useSelector } from "react-redux";
import FileUpload from "../components/file_upload/FileUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import PromoterHeader from "./layout/PromoterHeader";
import ProfileImage from "../components/profileImage/ProfileImage";
import Alert from "../components/alert/Alert";


export default () => {
    /** @type {User}*/
    const user = useSelector(store => store.site.user);
    const currentYear = new Date().getFullYear();
    const dispatch = useDispatch();

    const [validatedCounter, setValidatedCounter] = useState(1);
    const [qrZoom, setQrZoom] = useState(false);
    const [openGeneralData, setOpenGeneralData] = useState(false);
    const [openInvoiceData, setOpenInvoiceData] = useState(false);
    const [openPromoterData, setOpenPromoterData] = useState(false);
    const [openPasswordData, setOpenPasswordData] = useState(false);

    const passwordInput = useInput({ label: T("password"), id: 'password', type: 'password', rules: ['required'] });
    const newPasswordInput = useInput({
        label: T("new_password"),
        id: 'new-password',
        type: 'password',
        rules: ['required'],
        afterValidate: () => {
            setValidatedCounter(validatedCounter + 1)
        }
    });

    let dateArray = [];
    for (let i = 2010; i < currentYear + 10; i++) {
        dateArray.push(i);
    }


    const passwordAgainInput = useInput({
        label: T("password_again"),
        id: 'password-again',
        type: 'password',
        rules: [{
            key: 'compare',
            operator: '==',
            val: newPasswordInput.ref.current ? newPasswordInput.ref.current.value : 0
        }]
    });

    const generalLastNameInput = useInput({
        label: T("last_name"),
        id: 'lastname_input',
        value: user._profile.last_name,
        rules: ['required']
    });
    const generalFirstNameInput = useInput({
        label: T("first_name"),
        id: 'firstname_input',
        value: user._profile.first_name,
        rules: ['required']
    });
    const generalPhoneInput = useInput({
        label: T("phone"),
        id: 'phone_input',
        value: user._profile.phone,
        rules: ['required']
    });

    //invoice
    const addressNameInput = useInput({
        label: T("name"),
        id: 'address_name_input',
        value: user._address.name,
        rules: ['required']
    });
    const addressPostalInput = useInput({
        label: T("postal"),
        id: 'address_postal_input',
        value: user._address.postal,
        rules: ['required']
    });
    const addressCityInput = useInput({
        label: T("city"),
        id: 'address_city_input',
        value: user._address.city,
        rules: ['required']
    });
    const addressAddressInput = useInput({
        label: T("address"),
        id: 'address_address_input',
        value: user._address.address,
        rules: ['required']
    });
    const addressCountryInput = useInput({
        label: T("country"),
        id: 'address_country_input',
        value: user._address.country,
        rules: ['required']
    });
    const addressTaxIdInput = useInput({
        label: T("taxId"),
        id: 'address_taxid_input',
        value: user._address.tax_id,

    });
    //PROMOTER
    const promoterBirthDateInput = useInput({
        label: T("promoter_birth_date"),
        id: 'promoter_birthdate_input',
        type: 'date',
        value: user._profile.birth_date,
        rules: ['required'],
    });
    const promoterSchoolNameInput = useInput({
        label: T("promoter_school_name"),
        id: 'promoter_school_name_input',
        value: user._profile.school_name,
        rules: ['required']
    });

    const promoterSchoolStartYearInput = useInput({
        label: T("promoter_school_start_year"),
        id: 'promoter_school_start_input',
        as: 'select',
        value: user.getSchoolStartYear(),
        rules: ['required'],
        children: dateArray.map(item => <option value={item} key={'select_' + (item)}>{item}</option>)
    });
    const promoterFbProfileLinkInput = useInput({
        label: T("promoter_fb_profile_link"),
        id: 'promoter_fb_profile_input',
        value: user._profile.fb_profile_link,

    });
    const promoterInstagramProfileLinkInput = useInput({
        label: T("promoter_instagram_profile_link"),
        id: 'promoter_instagram_profile_input',
        value: user._profile.instagram_profile_link,

    });
    const promoterTiktokProfileLinkInput = useInput({
        label: T("promoter_tiktok_profile_link"),
        id: 'promoter_tiktok_profile_input',
        value: user._profile.tiktok_profile_link,

    });
    const promoterShortInfoInput = useInput({
        label: T("promoter_promoter_short_info"),
        id: 'promoter_promoter_short_input',
        value: user._profile.promoter_short_info,

    });

    useEffect(() => {
        user.getPromoterData();
    }, [user]);

    useEffect(() => {
        passwordAgainInput.validate();
    }, [newPasswordInput]);

    const handleLogout = () => {
        let user = User.get();
        user.logout();
    }

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        let isValid = passwordInput.validate();
        isValid = newPasswordInput.validate() && isValid;
        isValid = passwordAgainInput.validate() && isValid;
        if (isValid) {
            await Api.post('user/change-password', {
                api_token: user.api_token,
                user_id: user.id,
                password: passwordInput.ref.current.value,
                new_password: newPasswordInput.ref.current.value,
            });
        }
        e.target.reset();
    };
    const handleDataSubmit = async (e) => {
        e.preventDefault();
        let result = await Api.post('user/modify', {
            api_token: user.api_token,
            user_id: user.id,
            profile: {
                last_name: generalLastNameInput.ref.current.value,
                first_name: generalFirstNameInput.ref.current.value,
                phone: generalPhoneInput.ref.current.value,
                birth_date: promoterBirthDateInput.ref.current.value,
                school_name: promoterSchoolNameInput.ref.current.value,
                school_start_year: promoterSchoolStartYearInput.ref.current.value,
                fb_profile_link: promoterFbProfileLinkInput.ref.current.value,
                instagram_profile_link: promoterInstagramProfileLinkInput.ref.current.value,
                tiktok_profile_link: promoterTiktokProfileLinkInput.ref.current.value,
                promoter_short_info: promoterShortInfoInput.ref.current.value,
            },
            address: {
                name: addressNameInput.ref.current.value,
                postal: addressPostalInput.ref.current.value,
                city: addressCityInput.ref.current.value,
                address: addressAddressInput.ref.current.value,
                country: addressCountryInput.ref.current.value,
                tax_id: addressTaxIdInput.ref.current.value,
            }
        });
        if (result.success) {
            dispatch(siteActions.setAlert({ title: 'Sikeresen módosította az adatait', message: '', level: 'success' }));
        } else {
            dispatch(siteActions.setAlert({ title: 'Hiba', message: result.error, level: 'error' }));

        }

    }
    const handleZoom = () => {
        setQrZoom(!qrZoom);
    }

    return <div className="profile-page">
        <div className="d-none d-md-block">
            <PromoterHeader />
        </div>
        <Alert />
        <div className="panel br-sm-0">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="profile-back-btn-holder">
                    <Link to="/promoter" className="btn btn-outline me-2"><FontAwesomeIcon icon="fas fa-arrow-left" /> </Link>
                    <FontAwesomeIcon icon="far fa-user" /> <span className="fw-700"> Profil</span>
                </div>
                <div>
                    <span className="fw-500">Kijelentkezés</span>&nbsp;&nbsp;
                    <button className="btn btn-sm" type="button" onClick={handleLogout}> <FontAwesomeIcon icon="fa-solid fa-arrow-right-from-bracket" /></button>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 mb-4 mt-3">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-sm-6 text-center profile-image-holder">{!!user.promoter_image &&
                            <ProfileImage/>
                        }
                        </div>
                        <div className="col-sm-6 profile-qr-holder">
                            <div className={"qr-code  d-flex justify-content-center m-3 m-md-0"} onClick={handleZoom}
                                dangerouslySetInnerHTML={{ __html: user.qr_code }}></div>
                            {qrZoom && <div className="qr-zoom-holder" onClick={handleZoom}>
                                <div className={"qr-code qr-zoom show"}
                                    dangerouslySetInnerHTML={{ __html: user.qr_code }}></div>
                            </div>}
                        </div>
                        <div className="col-sm-6 text-center profile-upload-holder">
                            <FileUpload url="user/profile-image" />
                        </div>
                    </div>

                </div>

                <div className="col-md-6">
                    <form onSubmit={handleDataSubmit}>
                        <div className="profile-block">
                            <button className={"collapse-header " + (openGeneralData ? 'active' : '')} type="button"
                                onClick={() => setOpenGeneralData(!openGeneralData)}>{T('general_data')}
                            </button>
                            <Collapse className="collapse-body" in={openGeneralData}>
                                <div>
                                    <div className="p-3">
                                        {generalLastNameInput.jsx}
                                        {generalFirstNameInput.jsx}
                                        {generalPhoneInput.jsx}
                                        <div className="text-center">
                                            <button className="btn" type="submit">{T('save')}</button>
                                        </div>

                                    </div>
                                </div>
                            </Collapse>
                        </div>
                        <div className="profile-block">
                            <button className={"collapse-header " + (openInvoiceData ? 'active' : '')} type="button"
                                onClick={() => setOpenInvoiceData(!openInvoiceData)}>{T('invoice_data')}
                            </button>
                            <Collapse className="collapse-body" in={openInvoiceData}>
                                <div>
                                    <div className="p-3">
                                        {addressNameInput.jsx}
                                        {addressPostalInput.jsx}
                                        {addressCityInput.jsx}
                                        {addressAddressInput.jsx}
                                        {addressCountryInput.jsx}
                                        {addressTaxIdInput.jsx}
                                        <div className="text-center">
                                            <button className="btn" type="submit">{T('save')}</button>
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                        <div className="profile-block">
                            <button className={"collapse-header " + (openPromoterData ? 'active' : '')} type="button"
                                onClick={() => setOpenPromoterData(!openPromoterData)}>{T('promoter_data')}
                            </button>
                            <Collapse className="collapse-body" in={openPromoterData}>
                                <div>
                                    <div className="p-3 data">
                                        <p>
                                            <span className="title">{T('promoter')}: </span>{user.parentPromoter}
                                        </p>
                                        {user.children.length > 0 && <p>
                                            <span className="title">{T('my_team')}: </span>{user.children.join(', ')}
                                        </p>}
                                         {!!user.promoterJoinUsLink && <p>
                                            <span className="title">{T('send_this_link_and_recruit_a_team')}: </span><a href={user.promoterJoinUsLink}>{user.promoterJoinUsLink}</a>
                                        </p>}  
                                        {!!user.promoterPageQrCode && <p>
                                            <span className="title">{T('own_public_promoter_page')}: </span>
                                            <img src={user.promoterPageQrCode} alt="promoter_qr_code" />
                                            {!!user.promoterPageLink&& <a href={user.promoterPageLink}>{user.promoterPageLink}</a>}
                                        </p>}
                                    
                                    </div>
                                    <div className="p-3">
                                        {promoterBirthDateInput.jsx}
                                        {promoterSchoolNameInput.jsx}
                                        {promoterSchoolStartYearInput.jsx}
                                        {promoterFbProfileLinkInput.jsx}
                                        {promoterInstagramProfileLinkInput.jsx}
                                        {promoterTiktokProfileLinkInput.jsx}
                                        {promoterShortInfoInput.jsx}
                                        <div className="text-center">
                                            <button className="btn" type="submit">{T('save')}</button>
                                        </div>


                                    </div>
                                </div>
                            </Collapse>
                        </div>
                    </form>
                    <form onSubmit={handlePasswordSubmit}>
                        <div className="profile-block">
                            <button className={"collapse-header " + (openPasswordData ? 'active' : '')} type="button"
                                onClick={() => setOpenPasswordData(!openPasswordData)}>{T('password_data')}
                            </button>
                            <Collapse className="collapse-body" in={openPasswordData}>
                                <div>
                                    <div className="p-3">
                                        {passwordInput.jsx}
                                        {newPasswordInput.jsx}
                                        {passwordAgainInput.jsx}
                                        <div className="text-center">
                                            <button className="btn" type="submit">{T('password_change')}</button>
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>;
}
