import React, {useState} from "react";
import {T} from "../../core/helper";
import {Link, useSearchParams} from "react-router-dom";
import User from "../../models/User";
import useInput from "../input/Input";
import {useNavigate} from "react-router";

export default (props)=>{
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const [validatedCounter, setValidatedCounter] = useState(1);
    const navigate = useNavigate();

    const newPasswordInput = useInput({
        label: T("new_password"),
        id: 'new-password',
        type: 'password',
        rules: ['required'],
        afterValidate: () => {
            setValidatedCounter(validatedCounter + 1)
        }
    });

    const passwordAgainInput = useInput({
        label: T("password_again"),
        id: 'password-again',
        type: 'password',
        rules: [{
            key: 'compare',
            operator: '==',
            val: newPasswordInput.ref.current ? newPasswordInput.ref.current.value : 0
        }]
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        let isValid = newPasswordInput.validate();
        isValid = passwordAgainInput.validate() && isValid;
        if (isValid) {
            let result = await User.resetPassword(token,newPasswordInput.ref.current.value);
            newPasswordInput.ref.current.value = '';
            passwordAgainInput.ref.current.value = '';
            if(result){
                navigate('/');
            }
        }
    }
    return  <div className="login-holder">
        <div className="login-outer">
            <div className="login-inner">
                <form onSubmit={handleSubmit}>
                    <div>
                        {newPasswordInput.jsx}
                        {passwordAgainInput.jsx}
                    </div>

                    <div className="d-flex justify-content-between">
                        <Link to="/" className="button" type="submit">{T('login')}</Link>
                        <button type="submit">{T('send')}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>;
}
