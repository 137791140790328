import translate from "./translate";
import Store from "../store";

const T = (key, replace = false) => {
    let lang = "hu-HU";
    let row = translate.find((item) => item.key === key);
    if (!row) {
        console.error("Nincs lefordítva a következő kulcshoz tartozó szöveg: " + key);
        return "";
    }
    let text = row[lang];
    if (replace) {
        let replaceKeys = Object.keys(replace);
        let replaceList = [];
        replaceKeys.forEach((item) => {
            replaceList.push({key: "{" + item + "}", val: replace[item]});
        });
        replaceList.forEach((item) => {
            text = text.replaceAll(item.key, item.val);
        });
    }
    return text;
};

const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
    else return false;
}

const setCookie = (name, value, days) => {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
const GetSetting = (name) => {
    const {settings, siteSetting} = Store.getState().site;
    return settings[name]??'';
    let site_id = siteSetting.site_id ? +siteSetting.site_id : 0;
    let setting = settings.find(
        (item) => item.name === name && +item.site_id === site_id
    ); //site specifikus
    if (!setting) {
        setting = settings.find(
            (item) => item.name === name && +item.site_id === 0
        ); // általános
    }
    return setting ? setting.value : null;
};
/**
 * @param string? date
 * @return integer timestamp
 * */
const Timestamp = (date = null)=>{
    if(date){
        return Math.floor(new Date(date).getTime()/1000);
    }
    return Math.floor(new Date().getTime()/1000);
}


const removeControlCharacters = (inputText) => {
    // Vezérlő karakterek kifejezése egy reguláris kifejezés segítségével
    try {
        let controlCharacterRegex = /[\x00-\x1F\x7F]/g;

        // Vezérlő karakterek eltávolítása a szövegből
        return inputText.replace(controlCharacterRegex, '');
    }catch(e){
        return '';
    }
}

const getClipboardData = async () => {
    try {
        return await navigator.clipboard.readText();
    } catch (error) {
        console.error('Hiba történt a vágólap adatok lekérdezése során:', error);
        return null;
    }
}

const copyToClipboard = async(text) => {
    if (navigator.clipboard && navigator.clipboard.write) {
        return await navigator.clipboard.writeText(text)
            .then(async() => {
                const clipboardData = await getClipboardData();
                if(removeControlCharacters(clipboardData) == removeControlCharacters(text))
                    return true;
                return false;
            })
            .catch(async (error) => {
                console.error('Hiba történt a vágólapra másolás során:', error);
                return await fallbackCopyToClipboard(text);
            });
    } else {
        return await fallbackCopyToClipboard(text);
    }
}

// Fallback módszer, ha a navigator.clipboard API nem támogatott
const fallbackCopyToClipboard = async(text) =>  {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.setAttribute('readonly', '');
    textarea.style.position = 'absolute';
    textarea.style.left = '-9999px';
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    const clipboardData = await getClipboardData();
    if(clipboardData == text)
        return true;
    return false;
}

export {T, getCookie, setCookie, GetSetting, Timestamp, copyToClipboard}
