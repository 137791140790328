import React from "react";
import {T} from "../../core/helper";
import TicketRow from "./TicketRow";
import ticketIcon from "../../style/img/ticket-icon.svg";
export default ({ selectedId, handleSelectTicketType,ticketTypes}) => {

    return <div className="row-block ticket-type-table">
        <h2 className="title"><span className="icon"><img src={ticketIcon} alt="" /></span> {T('ticket_type')}</h2>
        <div className="row head desktop">
            <div className="col-md-2">{T('ticket_type')}</div>
            <div className="col-md-2">{T('price')}</div>
            <div className="col-md-2">{T('commission')}</div>
            <div className="col-md-2">{T('available')}</div>
            <div className="col-md-4">{T('description')}</div>
        </div>
        {!!ticketTypes && ticketTypes.map((ticket, index) => (
            <TicketRow key={index + 'ticket_type'}
                       ticket={ticket}
                       selectedId={selectedId}
                       handleSelectTicketType={handleSelectTicketType}/>
        ))}
    </div>;
}