import { createSlice } from "@reduxjs/toolkit";

const SiteSlice = createSlice({
    name: "site",
    initialState: {
        user:false,
        requestCount: 0,
        isLoaderVisible: false,
        siteInit: false,
        settings:false,
        events:[],
        vouchers:{selectedId:null,vouchers:[]},
        alert: {title:false,message:false,level:false},
        window_size: { width: 0, height: 0 },
        headerImg: null,
        articles: [],
        privacyArticles: [],
        showHeader: true,
    },
    reducers: {
        requestCountInc(state) {
            state.requestCount++;
        },
        requestCountDec(state) {
            state.requestCount--;
        },
        showLoader(state){
            state.isLoaderVisible = true;
        },
        hideLoader(state){
            state.isLoaderVisible = false;
        },
        siteInit(state, action) {
            state.siteInit = action.payload;
        },
        settings(state, action) {
            state.settings = action.payload;
        },
        user(state, action) {
            state.user = action.payload;
        },
        events(state, action) {
            state.events = action.payload;
        },
        setAlert(state, action){
            state.alert = action.payload;
        },
        window_size(state, action) {
            state.window_size = action.payload;
        },
        vouchers(state, action) {
            state.vouchers = action.payload;
        },
        removeAlert(state){
            state.alert = {title:false,message:false,level:false};
        },
        headerImg(state,action){
            state.headerImg = action.payload;
        },
        articles(state,action){
            state.articles = action.payload;
        },
        privacyArticles(state,action){
            state.privacyArticles = action.payload;
        },
        showHeader(state,action){
            state.showHeader = action.payload;
        }
    },
});

export const siteActions = SiteSlice.actions;
export default SiteSlice;
