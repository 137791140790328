import React, {useEffect} from "react";
import {T} from "../../core/helper";
import {Link} from "react-router-dom";
import config from "../../core/config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default ({article}) => {

    return <Link to={'/promoter/inside-article/' + article.slug}>
        <div className="card">
            {!!article.image && <div className="event-image-holder" style={{backgroundImage:"url("+config.API_DOMAIN + article.image+")"}}>
            </div>}
            <h3 className="title">{article.title}</h3>
            <div className="short" dangerouslySetInnerHTML={{__html: article.short}}></div>
            <div className="text-start">
                <button className="btn">{T('more')} <FontAwesomeIcon icon="fas fa-arrow-right"/></button>
            </div>
        </div>
    </Link>;
}