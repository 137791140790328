import React, {useEffect, useState} from "react";
import Event from "../models/Event";
import EventItem from "./partials/EventItem";
import {Link} from "react-router-dom";
import Pagination from "../components/pagination/Pagination";
import PromoterHeader from "./layout/PromoterHeader";
import Alert from "../components/alert/Alert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import boomLogoMobile from "../style/img/mobile-header.png";
import config from "../core/config";

export default (props) => {

    const [events, setEvents] = useState([]);
    const [eventsOnPage, setEventsOnPage] = useState([]);

    useEffect(() => {
        Event.getAll(true).then(events => {
            setEvents(events);
        })

    }, []);

    const handlePagination = (from, to) => {
        //Lapozóhoz kell ezt a függvényt hívja meg lapozáskor és ez fogja beállítani az adott oldalon lévő cikkeket
        let _eventsOnPage = events.slice(from, to);
        setEventsOnPage(_eventsOnPage);
        window.scrollTo(0, 0);
    }

    return <>
        <div className="d-none d-md-block">
            <PromoterHeader hideNav="true"/>
        </div>

        <div className="d-block d-md-none header-img" >
            <div className="promoter-page-header align-items-center">
                <img className="d-md-none w100 img-fluid" src={boomLogoMobile} alt="" />
            </div>
        </div>

        <Alert />

        <div className="panel">
            <div className="back-btn-holder">
                <Link to="/promoter" className="btn btn-outline"><FontAwesomeIcon icon="fas fa-arrow-left" /> </Link>
            </div>
            <h2 className="title mb-3">Lezajlott események</h2>
            <div className="row">
                {!!eventsOnPage && eventsOnPage.map((event, index) => <div key={index + "_event"} className="col-sm-3 event__item">
                    <EventItem event={event}/></div>)}
            </div>
            <Pagination setItem={handlePagination} count={events.length} itemOnPage={12}/>
        </div>
    </>;
}
