import React, {useEffect, useState} from "react";
import EventItem from "./EventItem";
import EventModel from "../../models/Event";
import calendarIcon from "../../style/img/calendar-icon.svg";


export default (props) => {
    const [events, setEvents] = useState([]);
    useEffect(()=>{
        EventModel.getAll().then((futureEvents) => {
            setEvents(futureEvents);
        });
    },[]);
    return <>
        <h2 className="title"><span className="icon"><img src={calendarIcon} alt="" /></span> Közelgő események</h2>
        <div className="row">
            {!!events && events.map((event, index) => <div key={index + "_event"} className="col-sm-4 event__item"><EventItem event={event}/></div>)}
        </div>
    </>;
}