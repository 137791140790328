import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { T } from "../core/helper";
import useInput from "../components/input/Input";
import useSelect from "../components/input/Select";
import User from "../models/User";
import Api from "../core/Api";
import Pagination from "../components/pagination/Pagination";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import moneyIconBlue from "./../style/img/money-icon-blue.svg";
import PromoterHeader from "./layout/PromoterHeader";

export default () => {
    const params = useParams();
    const isChild = params.type == 'child';
    let startDate = new Date()
    startDate.setMonth(startDate.getMonth() - 3);

    let endDate = new Date()
    endDate.setDate(endDate.getDate() + 1);

    let yyyystart = startDate.getFullYear();
    let mmstart = startDate.getMonth() + 1;
    let ddstart = startDate.getDate();

    let yyyyend = endDate.getFullYear();
    let mmend = endDate.getMonth() + 1;
    let ddend = endDate.getDate();

    if (ddstart < 10) ddstart = '0' + ddstart;
    if (mmstart < 10) mmstart = '0' + mmstart;

    if (ddend < 10) ddend = '0' + ddend;
    if (mmend < 10) mmend = '0' + mmend;

    const [transactions, setTransactions] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [isPaid, setIsPaid] = useState(null);

    const dateFromInput = useInput({
        id: 'date_from_input',
        showLabel: false,
        type: "date",
        value: `${yyyystart}-${mmstart}-${ddstart}`
    });
    const dateToInput = useInput({
        id: 'date_to_input',
        showLabel: false,
        type: "date",
        value: `${yyyyend}-${mmend}-${ddend}`
    });

    const filterData = async (page = 0) => {
        let user = User.get();
        let data = {
            user_id: user.id,
            api_token: user.api_token,
            child_commission: params.type == 'child',
            date_from: dateFromInput.ref.current.value ?? null,
            date_to: dateToInput.ref.current.value,
            page: page,
            count_on_page: 10,
        };
        if (isPaid != null)
            data.is_paid = isPaid;
        let result = await Api.post('user/transactions', data);
        if (result) {
            setTransactions(result.transactions);
            setPageCount(result.page_count);
        }
    }

    useEffect(() => {
        filterData();
    }, []);

    const handleFilter = (e) => {
        e.preventDefault();
        let isValid = dateFromInput.jsx;
        isValid = dateToInput.jsx && isValid;
        if (isValid) {
            filterData();
        }
    }

    const handlePaginate = (from, to, page) => {
        filterData(page - 1);
    }
    return <div className="white-bg-mobile">
        <div className="d-none d-md-block">
            <PromoterHeader />
        </div>

        <div className="panel mb-4 br-sm-0">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="profile-back-btn-holder">
                    <Link to="/promoter" className="btn btn-outline me-2"><FontAwesomeIcon icon="fas fa-arrow-left" /> </Link>
                    <img src={moneyIconBlue} alt="" /> <span className="fw-700"> {params.type == 'own' ? T('balance') : T('child_promoter_balance')}</span>
                </div>

            </div>




            <div className="filter-box mt-4 mb-4">
                <h3 className="title"><span className="icon me-2"><FontAwesomeIcon icon="fas fa-sliders-h" /></span>{T('edit_filters')}</h3>
                <form onSubmit={handleFilter}>
                    {dateFromInput.jsx}
                    {dateToInput.jsx}
                    <div className="row mb-3">
                        <div className="col-sm-3 mb-2">
                            <button className={"filter-btn me-2 " + (isPaid == 1 ? 'active' : '')} onClick={() => { setIsPaid(isPaid == null ? 1 : null); }}>{T('paid')}</button>
                        </div>
                        <div className="col-sm-3">
                            <button className={"filter-btn " + (isPaid == 0 ? 'active' : '')} onClick={() => { setIsPaid(isPaid == null ? 0 : null); }}>{T('not_paid')}</button>
                        </div>
                        <div className="col-sm-3"></div>
                        <div className="col-sm-3"></div>
                    </div>
                    <button className="btn" type="submit">Szűrés</button>
                </form>
            </div>

            <div className={`performance-grid grid-col-${isChild ? '5' : '4'}`}>
                <div className="desktop performance-grid__header">Összeg</div>
                <div className="desktop performance-grid__header">Kifizetés</div>
                <div className="desktop performance-grid__header">Dátum</div>
                <div className="desktop performance-grid__header">Megjegyzés</div>
                {isChild && <div className="desktop performance-grid__header">Csapattag</div>}
                {!transactions && <p>Nincs Tranzakció</p>}

                {transactions.map((transaction, index) => <React.Fragment key={index}>
                    <div>
                        <span className="mobile"><strong>Összeg: </strong></span>
                        {isChild ? transaction.sum_child_amount : transaction.amount}</div>
                    <div>
                        <span className="mobile"><strong>Kifizetés: </strong></span>
                        {transaction.payment ? T('paid') : T('not_paid')}</div>
                    <div>
                        <span className="mobile"><strong>Dátum: </strong></span>
                        {transaction.date}</div>
                    <div>
                        <span className="mobile"><strong>Megjegyzés: </strong></span>
                        {transaction.comment}</div>
                    {isChild && <div>
                        <span className="mobile"><strong>Csapattag: </strong></span>
                        {transaction.child_promoter_name}</div>}
                    <hr className="hr-blue mobile-block" />
                </React.Fragment>
                )}
            </div>

            <Pagination setItem={handlePaginate} itemOnPage={10} count={pageCount * 10} />
        </div>
    </div>;
}
