import React from "react";
import {Link,Navigate} from "react-router-dom";
import User from "../models/User";
import {useSelector} from "react-redux";

export default (props) => {
    const userStore = useSelector(state=>state.site.user);
    let user = new User();
    user.load(userStore);

    const isPromoter = user.hasPermission('promoter');
    const isTicketController = user.hasPermission('ticket_controller');

    if(isPromoter && isTicketController) {
        return <div className="selector-holder d-flex justify-content-center">
            <div><Link className="btn button-xl" to={'/promoter'}>Promoter felület</Link></div>
            <div><Link className="btn button-xl" to={'/check-in'}>Jegyellenőrzés</Link></div>
        </div>;
    }else{
        if(isPromoter){
            return <Navigate to="/promoter" />;
        }else if(isTicketController){
            return <Navigate to="/check-in" />;
        }
    }
    return <h1>NINCS JOGOSULTSÁGA</h1>
}