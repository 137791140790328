
import Model from "../core/model";
import Api from "../core/Api";
import Store from "../store";
import TicketType from "./TicketType";
import {Timestamp} from "../core/helper";
/**
 * @property id {string}
 * @property _name {string}
 * @property brand_id {string}
 * @property campaign_description {string}
 * @property cover_image {string}
 * @property created_at {string}
 * @property created_by {string}
 * @property deleted {string}
 * @property description {string}
 * @property end_date {string}
 * @property facebook_link {string}
 * @property gallery_id {string}
 * @property gate_opening_time {string}
 * @property header_image {string}
 * @property instagram_link {string}
 * @property location_address {string}
 * @property location_lat {string}
 * @property location_long {string}
 * @property location_name {string}
 * @property long_name {string}
 * @property max_ticket {string}
 * @property organizer_company_id {string}
 * @property slug {string}
 * @property start_date {string}
 * @property status {string}
 * @property tiktok_link {string}
 * @property updated_at {string}
 * @property updated_by {string}
 * @property video_url {string}
 * @property youtube_link {string}
 * @property id {string}
 * */

class Event extends Model {
    constructor() {
        super();
        this._name = false;

    }

    static getUrl = 'event/event';
    static getBySlugUrl = 'event/event';
    static getOrderLinkUrl = 'event/get-order-link';
    static getAttachmentUrl = 'event/attachment';


    static async getAll(expired = false, ticketCheck=false) {
        let user = Store.getState().site.user;
        let result = await Api.post(Event.getUrl, {user_id: user.id, api_token: user.api_token, is_expired: expired,ticketCheck:ticketCheck});
        let events = [];
        for (const event of result.event) {
            events.push(Event.loadNew(event));
        }
        return events;
    }


    static async getBySlug(slug) {
        let user = Store.getState().site.user;
        let result = await Api.post(Event.getBySlugUrl, {user_id: user.id, api_token: user.api_token, slug: slug});
        if (result.data.length) {
            return Event.loadNew(result.data[0]);
        }
        return {};
    }
    /**
     * minden típusú attachment linket ad vissza
     * */
    async getAttachments(){
        let user = Store.getState().site.user;
        let result = await Api.post(Event.getAttachmentUrl, {user_id:user.id,api_token:user.api_token, event_id:this.id});
        return result.links.length>0? result.links : [];
    }

    async getTicketTypes() {
        return await TicketType.get(this.id);
    }
    /**
     * @function isActive
     * @return boolean
     * */
    isActive(){
        if(Timestamp(this.end_date) >= Timestamp()){
            return true;
        }
        return false;
    }
}

export default Event;
