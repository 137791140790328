import React from "react";

import {Outlet} from "react-router";
import Header from "./Header";
import Alert from "../../components/alert/Alert";
import PromoterHeader from "./PromoterHeader";

export default (props)=>{
    return <>
        <Header/>
        <Alert/>

        <div className="container-xl asdasdasd app-body"><Outlet/></div>
    </>;
}