const translate = [
    {key:'yes',                     'hu-HU':'Igen',                  'en-US':'Yes' },
    {key:'no',                      'hu-HU':'Nem',                   'en-US':'No' },
    {key:'login',                   'hu-HU':'Bejelentkezés',         'en-US':'Login' },
    {key:'username',                'hu-HU':'Felhasználónév',        'en-US':'Username' },
    {key:'password',                'hu-HU':'Jelszó',                'en-US':'Password' },
    {key:'new_password',            'hu-HU':'Új jelszó',             'en-US':'New password' },
    {key:'password_again',          'hu-HU':'Jelszó újra',           'en-US':'Password again' },
    {key:'forgot_password',         'hu-HU':'Elfelejtett jelszó',    'en-US':'Forgot password' },
    {key:'logout',                  'hu-HU':'Kijelentkezés',         'en-US':'Logout' },
    {key:'email',                   'hu-HU':'E-mail',                'en-US':'E-mail' },
    {key:'more',                    'hu-HU':'Tovább',                'en-US':'More' },
    {key:'delete',                  'hu-HU':'Törlés',                'en-US':'Delete' },
    {key:'event',                   'hu-HU':'Esemény',               'en-US':'Event' },
    {key:'ticket_type',             'hu-HU':'Jegytípus',             'en-US':'Ticket type' },
    {key:'name',                    'hu-HU':'Név',                   'en-US':'Name' },
    {key:'quantity',                'hu-HU':'Mennyiség',             'en-US':'Quantity' },
    {key:'price',                   'hu-HU':'Ár',                    'en-US':'Price' },
    {key:'commission',              'hu-HU':'Jutalék',               'en-US':'Commission' },
    {key:'available',               'hu-HU':'Helyek száma',          'en-US':'Available' },
    {key:'description',             'hu-HU':'Leírás',                'en-US':'Description' },
    {key:'balance',                 'hu-HU':'Egyenleged',            'en-US':'Balance' },
    {key:'knowledge_level',         'hu-HU':'Tudástár szint',        'en-US':'Knowledge level' },
    {key:'fast_list',               'hu-HU':'Gyorslista felírás',    'en-US':'Fast list' },
    {key:'sent_quantity',           'hu-HU':'Kiküldve/Letöltve',     'en-US':'Sent/Downloaded' },
    {key:'edit_filters',            'hu-HU':'Szűrők szerkesztése',   'en-US':'Edit filters' },
    {key:'ticket_types',            'hu-HU':'Jegytípusok',           'en-US':'Ticket types' },
    {key:'sent_vouchers',           'hu-HU':'Elküldött voucherek',   'en-US':'Sent vouchers' },
    {key:'upload',                  'hu-HU':'Feltöltés',             'en-US':'Upload' },
    {key:'save',                    'hu-HU':'Mentés',                'en-US':'Save' },
    {key:'send',                    'hu-HU':'Küldés',                'en-US':'Send' },
    {key:'child_promoter_balance',  'hu-HU':'Csapatvezetői jutalék', 'en-US':'Child promoter balance' },
    {key:'paid',                    'hu-HU':'Kifizetés',             'en-US':'Paid' },
    {key:'not_paid',                'hu-HU':'Jutalék jóváírás',      'en-US':'Not paid' },
    {key:'please_select',           'hu-HU':'Kérlek válassz',        'en-US':'Please select' },
    {key:'event_creatives',         'hu-HU':'Esemény kreatívjai',    'en-US':'Event\'s creatives' },
    {key:'last_name',               'hu-HU':'Vezetéknév',            "en-US":'Last name' },
    {key:'first_name',              'hu-HU':'Keresztnév',            "en-US":'First name' },
    {key:'phone',                   'hu-HU':'Telefon',               "en-US":'Phone' },
    {key:'name',                    'hu-HU':'Név',                   "en-US":'Name' },
    {key:'postal',                  'hu-HU':'Irányítószám',          "en-US":'Postal' },
    {key:'city',                    'hu-HU':'Város',                 "en-US":'City' },
    {key:'address',                 'hu-HU':'Cím',                   "en-US":'Address' },
    {key:'country',                 'hu-HU':'Ország',                "en-US":'Country' },
    {key:'taxId',                   'hu-HU':'Adószám (cég esetén)',  "en-US":'Tax id (Company)' },
    {key:'general_data',            'hu-HU':'Általános adatok',      "en-US":'General data' },
    {key:'invoice_data',            'hu-HU':'Számlázási adatok',     "en-US":'Invoice data' },
    {key:'promoter_data',           'hu-HU':'Promoteri adatok',      "en-US":'Promoter data' },
    {key:'promoter',                'hu-HU':'Csapatvezető',          "en-US":'Parent promoter' },
    {key:'password_data',           'hu-HU':'Jelszó módosítás',      "en-US":'Password change' },
    {key:'used_places',             'hu-HU':'Felhasználva',          "en-US":'Used' },
    {key:'is_paid',                 'hu-HU':'Fizetve',               "en-US":'Paid' },
    {key:'validated_at',            'hu-HU':'Érvényesítve',          "en-US":'Validated at' },
    {key:'my_team',                 'hu-HU':'Csapatom',              "en-US":'My team' },
    {key:'promoter_birth_date',              'hu-HU':'Születési év',                   "en-US":'Birth date' },
    {key:'promoter_school_name',             'hu-HU':'Iskola neve',                    "en-US":'School name' },
    {key:'promoter_school_start_year',       'hu-HU':'Iskola várható végzési éve',     "en-US":'School end year' },
    {key:'promoter_fb_profile_link',         'hu-HU':'Facebook profile link',          "en-US":'Fb profile link' },
    {key:'promoter_instagram_profile_link',  'hu-HU':'Instagram profile link',         "en-US":'Instagram profile link' },
    {key:'promoter_tiktok_profile_link',     'hu-HU':'TikTok profile link',            "en-US":'Tiktok profile link' },
    {key:'promoter_promoter_short_info',     'hu-HU':'Rövid infó',                     "en-US":'Promoter short info' },
    {key:'password_change',                  'hu-HU':'Jelszó módosítása',              "en-US":'Password change' },
    {key:'the_voucher_sent_successful',      'hu-HU':'A voucher sikeresen elküldve',   'en-US':'The voucher sent successful' },
    {key:'the_voucher_deleted_successful',   'hu-HU':'A voucher sikeresen törölve',    'en-US':'The voucher delete successful' },
    {key:'the_voucher_deleted_unsuccessful', 'hu-HU':'A voucher törlése sikertelen',   'en-US':'The voucher delete unsuccessful' },
    {key:'sold_out',                         'hu-HU':'Elfogyott',                      'en-US':'Sold out' },
    {key:'well_come_to_playground',          'hu-HU':'Üdvözöllek a BOOM Playgroundon!','en-US':'Welcome to BOOM Playground!' },
    {key:'actual_balance',                   'hu-HU':'Aktuális egyenleged',            'en-US':'Actual balance' },
    {key:'performance_report',               'hu-HU':'Teljesítmény riport',            'en-US':'Performance report' },
    {key:'find_out_everything',              'hu-HU':'Tudj meg mindent!',              'en-US':'Find out everything!' },
    {key:'next',                             'hu-HU':'Következő',                      'en-US':'Next' },
    {key:'prev',                             'hu-HU':'Előző',                          'en-US':'Previous' },
    {key:'edit_filters',                     'hu-HU':'Szűrők szerkesztése',            'en-US':'Edit filters' },
    {key:'send_this_link_and_recruit_a_team','hu-HU':'Küldd tovább ezt a linket és gyűjts saját csapatot',    'en-US':'Send this link and recruit a team' },
    {key:'own_public_promoter_page',         'hu-HU':'Saját, nyilvános promóteri affiliate oldalad',    'en-US':'Own public promoter page' },





];
export default translate;
