import { Link } from "react-router-dom";
import config from "../core/config";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import TicketTypeModel from "../models/TicketType";
import TicketType from "../components/ticket_type/TicketType";
import SentVoucher from "../components/sent_voucher/SentVoucher";
import VoucherSend from "../components/voucher_send/VoucherSend";
import { T } from "../core/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PromoterHeader from "./layout/PromoterHeader";
import { siteActions } from "../store/SiteSlice";

import imageIcon from "../style/img/img-icon.svg";
import boomLogoMobile from "../style/img/mobile-header.png";
import Alert from "../components/alert/Alert";

export default () => {
    const params = useParams();
    const events = useSelector((state) => state.site.events);
    /**@type {Event}*/
    const event = events.find((item) => item.slug === params.slug) ?? false;
    let headerImg = useSelector(store => store.site.headerImg);

    const [selectedTicketType, setSelectedTicketType] = useState(false);
    const [ticketTypes, setTicketTypes] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (event.id) {
            TicketTypeModel.get(event.id).then(result => {
                setTicketTypes(result);
            });
            event.getAttachments().then(result => {
                setAttachments(result);
            });
            dispatch(siteActions.headerImg(event.header_image));
        }
    }, [event]);

    if (event === false) {
        return <div></div>;
    }
    const handleSelectTicketType = (ticketType) => {
        if (selectedTicketType == ticketType.id) {
            setSelectedTicketType(false);
        } else {
            setSelectedTicketType(ticketType.id);
        }
    };

    return <div>
        <div className="d-none d-md-block header-img">
            <PromoterHeader hideNav="true" />
        </div>
        <Alert />
        <div className="d-block d-md-none header-img" >
            <div className="promoter-page-header align-items-center">
                <img className="d-md-none w100 img-fluid" src={headerImg?config.API_DOMAIN+headerImg: boomLogoMobile} alt="" />
            </div>
        </div>
        <div className="panel">
            <div className="back-btn-holder">
                <Link to="/promoter" className="btn btn-outline"><FontAwesomeIcon icon="fas fa-arrow-left" /> </Link>
            </div>
            <div className="event__page">

                <h1 className="title">
                    {event._name}
                </h1>
                <p className="text-pink fw-500">
                <span className="me-4">
                    <span className="text-blue"><FontAwesomeIcon icon="fas fa-map-marker-alt"/></span>
                    &nbsp;{event.location_name}
                </span>
                <span>
                    <span className="text-blue"><FontAwesomeIcon icon="fas fa-calendar-alt"/></span>
                    &nbsp;{event.start_date}
                </span>
            </p>
                
            {!!event && ticketTypes.length>0 && <>
                {event.isActive() && <VoucherSend event={event} ticketTypes={ticketTypes} />}
                <div className="mb-4">
                    <TicketType ticketTypes={ticketTypes} selectedId={selectedTicketType}
                        handleSelectTicketType={handleSelectTicketType} />
                </div>
                <hr className="hr-grey" />
                
                <div>
                    {!!selectedTicketType && <SentVoucher eventId={event.id} selectedId={selectedTicketType} />}
                </div>
                {attachments.length > 0 &&
                    <div className="attachments">
                        <h2 className="title"><span className="icon"><img src={imageIcon} alt="" /></span>  {T("event_creatives")}</h2>
                
                        <div>
                            {attachments.map((item, index) => <div key={"attachment_" + index}>
                                <FontAwesomeIcon className="attachment-fa" icon={["fas", "file"]} /><a className="attachment-link" href={config.API_DOMAIN + item.link}>{item.title}</a>
                            </div>)}
                        </div>
                    </div>
                }
                </>}
            </div>
        </div>
    </div>;
}
