import model from "../core/model";
import Api from "../core/Api";
import Store from "../store";
import {siteActions} from "../store/SiteSlice";
import {T} from "../core/helper";
/**
 * @property {int} id
 * @property {int} event_id
 * @property {int} ticket_type_id
 * @property {string} recipient_name
 * @property {string} email
 * @property {int} available_places
 * @property {string} ticket_type_name
 * @property {int} created_at
 * @property {int} original_available_places
 * @property {int} promoter_id
 * @property {int} status
 * @property {Array} notDeletedTicket
 *
 * @property {int} is_copy
 * */

class Voucher extends model{
    constructor() {
        super();
        this._name = false;
    }
    static getUrl = 'voucher/sent-voucher';
    static sendUrl = 'voucher/send';
    static deleteUrl = 'voucher/delete';


    static async get(eventId,ticketTypeId){
        let user = Store.getState().site.user;
        let result = await Api.post(Voucher.getUrl, {user_id:user.id, api_token:user.api_token, event_id:eventId,ticket_type_id:ticketTypeId});
        let vouchers = [];
        if(!result.voucher){
            result.voucher = [];
        }
        for(const voucher of result.voucher){
            vouchers.push(Voucher.loadNew(voucher));
        }
        return vouchers;
    }
    async send(){
        let user = Store.getState().site.user;
        let result = await Api.post(Voucher.sendUrl, {user_id:user.id, api_token:user.api_token, ...this});
        return result;
    }

    async delete(){
        let user = Store.getState().site.user;
        let result = await Api.post(Voucher.deleteUrl, {user_id:user.id, api_token:user.api_token, event_id:this.event_id, voucher_id:this.id});
        if(result.success){
            Store.dispatch(siteActions.setAlert({title: T('the_voucher_deleted_successful'), message: '', level: 'success'}));
        }else {
            Store.dispatch(siteActions.setAlert({title: T('the_voucher_deleted_unsuccessful'), message: '', level: 'error'}));
        }
        setTimeout(()=>{
            Store.dispatch(siteActions.removeAlert());
        },3000);
        return true;
    }
}
export default Voucher;
