import React from "react";
import {T} from "../../core/helper";
import {Link} from "react-router-dom";
import User from "../../models/User";
import useInput from "../input/Input";

export default (props)=>{
    const usernameInput = useInput({
        id:'username-input',
        label:T('email'),
        rules:["required"]
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        if (usernameInput.ref?.current?.value) {
            User.forgotPassword(usernameInput.ref.current.value);
            usernameInput.ref.current.value = '';
        }
    }
    return  <div className="login-holder">
        <div className="login-outer">
            <div className="login-inner">
                <form onSubmit={handleSubmit}>
                    <div>
                        {usernameInput.jsx}
                    </div>

                    <div className="d-flex justify-content-between">
                        <Link to="/" className="button" type="submit">{T('login')}</Link>
                        <button type="submit">{T('send')}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>;
}
