import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { T } from "../core/helper";
import { useDispatch, useSelector } from "react-redux";
import EventList from "./partials/EventList";
import ArticleList from "./partials/ArticleList";
import VoucherSend from "../components/voucher_send/VoucherSend";
import config from "../core/config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Article from "../models/Article";
import { siteActions } from "../store/SiteSlice";
import Alert from "../components/alert/Alert";
import Dropdown from 'react-bootstrap/Dropdown';
import PromoterHeader from "./layout/PromoterHeader";
import { Collapse } from "react-bootstrap";

import boomLogoMobile from "../style/img/mobile-header.png";
import moneyIcon from "../style/img/money-icon.svg";
import groupIcon from "../style/img/group-icon.svg";
import editIcon from "../style/img/edit-icon.svg";
import fileIcon from "../style/img/file-icon.svg";
import ProfileImage from "../components/profileImage/ProfileImage";
import ScrollToAnchor from "../components/scroll_to_anchor/ScrollToAnchor";

export default () => {
    /** @type {User} */
    const user = useSelector(state => state.site.user);

    const [isInitialized, setIsInitialized] = useState(false);
    const [isOpenFastList, setIsOpenFastList] = useState(false);
    const privacyArticles = useSelector(state => state.site.privacyArticles);
    const dispatch = useDispatch();
    useEffect(() => {
        user.getPromoterData().then((_) => {
            setIsInitialized(true);
        });
    }, []);

    useEffect(() => {
        dispatch(siteActions.headerImg(null));

        Article.getPrivacyAndTac().then((_articles) => {
            dispatch(siteActions.privacyArticles(_articles));
        });
    }, []);

    if (!isInitialized) {
        return <></>;
    }
    return <div>
         
        <div className="d-none d-md-block header-img">
            <PromoterHeader/>
        </div>
        <div className="d-block d-md-none">
            <div className="container">
                <div className="row promoter-page-header align-items-center">
                    <div className="col-6">
                        <Link to="/"><img className="d-md-none" src={boomLogoMobile} alt="" /></Link>
                    </div>
                    <div className="col-6 text-right">
                        {!!user && <div className={"d-flex align-items-center flex-column-reverse flex-md-row justify-content-end "}>
                            <Dropdown>
                                <Dropdown.Toggle className="nav-button">
                                    {user._profile.name}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Link className="dropdown-item" to="/profile">Profil</Link>
                                    <button className="dropdown-item" onClick={() => { user.logout(); }}>Kijelentkezés</button>
                                    <div style={{ textAlign: 'right', paddingRight: '10px' }}><small
                                        style={{ color: '#aaaaaa' }}>{config.APP_VERSION}</small></div>
                                </Dropdown.Menu>
                            </Dropdown>
                            <ProfileImage isSm={true}/>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
        <Alert />

        <div className="panel promoter">
            <div className="row">
                <div className="col-md-6">
                    <div className="d-flex promoter-welcome">
                        <div>
                            <h1 className="title">Szia, {user._profile.name}!</h1>
                            <p className="text-pink fw-500">{T('well_come_to_playground')}</p>
                        </div>
                        <div className="flex-shrink-0">
                           
                        </div>
                    </div>
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                </div>
            </div>
            <div className="row widgets">
                <div className="col-sm-4 mb-20 d-block d-md-none">
                    <button className={"btn btn-widget w100"} type="button" onClick={() =>setIsOpenFastList(!isOpenFastList)}>
                        <div className="icon">
                                <img src={editIcon} alt="" />
                        </div>
                        <div>
                            <div className="title">{T('fast_list')}</div>
                        </div>
                    </button>
                    <Collapse className="collapse-body" in={isOpenFastList}>
                        <div className="pt-4"><VoucherSend /></div>
                    </Collapse> 
                </div>
                <div className="col-sm-4 mb-20">
                    <Link className="btn btn-widget" to="/transactions/own">
                        <div className="icon">
                            <img src={moneyIcon} alt="" />
                        </div>
                        <div>
                            <div className="title">{T('actual_balance')}</div>
                            <div className="light-text">
                                {user.balance || 0} Ft
                            </div>
                        </div>
                    </Link>
                </div>

                {(!!user.balance_child || user.has_child_promoter) && <div className="col-sm-4 mb-20">
                    <Link className="btn btn-widget" to="/transactions/child">
                        <div className="icon">
                            <img src={groupIcon} alt="" />
                        </div>
                        <div>
                            <div className="title">{T('child_promoter_balance')}</div>
                            <div className="light-text">
                                {user.child_balance || 0} Ft
                            </div>
                        </div>
                    </Link>
                </div>}

                <div className="col-sm-4 mb-20">
                    <Link className="btn btn-widget" to={'/promoter/performance-report'}>
                        <div className="icon">
                            <img src={fileIcon} alt="" />
                        </div>
                        <div>
                            <div className="title">{T('performance_report')}</div>
                        </div>
                    </Link>

                </div>
            </div>
            <h2 className="title d-none d-md-block"><span className="icon"><FontAwesomeIcon icon={"fas fa-pencil"} /></span> {T('fast_list')}</h2>
            <div className="mb-5 d-none d-md-block">
                <VoucherSend />
            </div>
            <EventList />
            <div className="text-center mb-5 mt-4"><Link to="/promoter/events" className="btn">Lezajlott
                események <FontAwesomeIcon icon="fas fa-arrow-right" /></Link></div>
            <h2 className="title" id="tudj-meg-mindent"><span className="icon">
                <FontAwesomeIcon icon="fas fa-graduation-cap" />
            </span> {T('find_out_everything')}</h2>
            <div className="mb-5">
                <ArticleList />
            </div>
            {privacyArticles.length > 0 && <div className="row">
                {privacyArticles.map((article, index) => <div className="col-sm-6" key={"privacy-article-index_" + index}>
                    {article && <Link to={'/promoter/inside-article/' + article.slug}>
                        <div className="row">
                            <div className="col-3 mb-3">
                                <img src={config.API_DOMAIN + article.image} className="img-fluid border-radius-10" />
                            </div>
                            <div className="col-9">
                                <h3 className="title">{article.title}</h3>
                                <div className="text-pink" dangerouslySetInnerHTML={{ __html: article.short }}></div>
                            </div>
                        </div>
                    </Link>}
                </div>)}
            </div>}
        </div>
    </div>;
}